import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import FeatureFunctions from '../../components/AboutUs/FeatureFunctions'
import featureImg from '/static/img/security-features/security-center-Oauth2.webp'
import {graphql} from 'gatsby'
const img = '/img/security-features/security-center-Oauth2.webp'

const dataOauth = {
  title: "OAuth2: A Secure Authorization Protocol for All Major Providers",
  subtitle: <div>
 <p>When adding your account, BlueMail uses highly private and secure authentication protocols, wherever applicable.</p>
 <p><b>OAuth2</b> is a widely used authentication protocol supported by most major providers including Gmail, Google Suite, Office365, and Yahoo, and when authenticating your account via OAuth, BlueMail redirects you to a webpage to enter your credentials. As such, BlueMail does not have access to your account password at any time.</p>
 <p>The OAuth process involves direct authentication with your email provider, followed by a unique token granted to BlueMail by your provider to access your emails, calendar and contacts information. This secure flow gives you complete control of your account and you can revoke this access at any given time, on your providers settings and management pages.</p>
 <p>With OAuth support by BlueMail, your email account credentials (i.e. username and password) never leave your side and are not transferred, giving you the complete assurance that your emails are safe and secure.</p>
  </div>,
  img: featureImg,
  class: ''
}

const Oauth = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='OAuth2 Security Protocol | BlueMail App' 
                postDescription='When adding your account, BlueMail uses highly private and secure authentication protocols and does not have access to your account password at any time.'
                postImage="/img/OG/og_image-security-center-Oauth2.png"
                postURL='security/oauth'
                postSEO
            />
            <Navbar />
            <FeatureFunctions data={dataOauth} />
            <Footer />
        </Layout>
    )
}

export default Oauth

export const query = graphql`
query OauthQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
